import { getAction, getByIdAction, postAction, putAction, getByTypeAction, deleteByIdAction } from '@/api/common/request'
const baseURL = 'xun/work/xunAttendanceReceipt'
//调用 import { addAttend } from "@/api/work/attendance/receipt";
//出差发票
//列表
const pagelistApi = (params) => getAction(baseURL + '/pagelist', params);

//新增 or 编辑
const addApi = (params) => postAction(baseURL + '/add', params);

//删除
const deleteByIdApi = (params) => deleteByIdAction(baseURL + '/deleteById', params);
  
//获取发票
const listApi = (params) => getAction(baseURL + '/list', params);

//获取事件
const getEventDataApi = (params) => getAction(baseURL + '/getEventData', params);

 
export {
    pagelistApi,
    addApi,
    deleteByIdApi,
    listApi,
    getEventDataApi
}
