import { getAction, getByIdAction, postAction, putAction, getByTypeAction, deleteByIdAction } from '@/api/common/request'
const baseURL = 'xun/work/xunAttendanceReimburse'
//调用 import { addAttend } from "@/api/work/attendance/reimburse";
//考勤报销表
//列表
const pagelistApi = (params) => getAction(baseURL + '/pagelist', params);

//新增 or 编辑
const saveOrUpdateApi = (params) => postAction(baseURL + '/saveOrUpdate', params);

//获取发票
const updateStatusApi = (params) => putAction(baseURL + '/updateStatus', params);

//删除
const deleteByIdApi = (params) => deleteByIdAction(baseURL + '/deleteById', params);

export {
    pagelistApi,
    saveOrUpdateApi,
    updateStatusApi,
    deleteByIdApi
}
