<template>
  <div>
    <el-dialog title="报销明细" :visible.sync="dialogVisible" width="87%" :before-close="handleClose">
      <!-- 列表区域 -->
      <el-button @click="handleClose">关闭</el-button>
      <el-table :data="attendanceList" border stripe style="width: 100%" show-summary>
        <el-table-column label="序号" type="index" width="50" fixed="left"></el-table-column>
        <el-table-column
          label="操作"
          align="center"
          class-name="small-padding fixed-width"
          width="120"
          fixed="left"
        >
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="text"
              icon="el-icon-edit"
              @click="editRowMember(scope.$index,attendanceList, scope.row)"
              v-if="!scope.row.isEdit"
            >修改</el-button>
            <el-button
              size="mini"
              type="text"
              icon="el-icon-edit"
              @click="addRowMember(scope.$index, scope.row)"
              v-else
            >保存</el-button>

            <el-button
              size="mini"
              type="text"
              icon="el-icon-edit"
              @click.native.prevent="deleteRow(scope.$index, scope.row,attendanceList)"
            >删除</el-button>
          </template>
        </el-table-column>
        <el-table-column label="发票事情名称" prop="name" width="100" />
        <el-table-column label="绑定事件" prop="resourcesIds" width="220">
          <template slot-scope="scope">
            <el-select multiple v-model="scope.row.resourcesIds" placeholder="请选择" disabled>
              <el-option
                v-for="item in resourcesIdList"
                :key="item.id"
                :label="item.name"
                :value="item.id+''"
              ></el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label="报销开始时间" prop="startTime" />
        <el-table-column label="报销结束时间" prop="endTime" width="260">
          <template slot-scope="scope">
            <el-date-picker
              v-model="scope.row.endTime"
               :disabled="!scope.row.isEdit"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd"
              type="date"
              placeholder="选择日期"
            ></el-date-picker>
          </template>
        </el-table-column>
        <el-table-column label="报销金额" prop="money" />
        <el-table-column label="实际金额" prop="actualMoney" />
        <el-table-column label="到手金额" prop="getMoeny" width="220">
          <template slot-scope="scope">
            <el-input-number
              class="edit-input"
              v-model="scope.row.getMoeny"
              :disabled="!scope.row.isEdit"
              placeholder="请输入内容"
            ></el-input-number>
          </template>
        </el-table-column>

        <el-table-column label="备注" prop="remark" width="200">
          <template slot-scope="scope">
            <el-input
              class="edit-input"
              v-model="scope.row.remark"
              :disabled="!scope.row.isEdit"
              placeholder="请输入内容"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column label="状态" prop="status" width="180">
          <template slot-scope="scope">
            <el-select v-model="scope.row.status" placeholder="请选择" :disabled="!scope.row.isEdit">
              <el-option
                v-for="item in statusList"
                :key="item.key"
                :label="item.name"
                :value="item.key"
              ></el-option>
            </el-select>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>
<script>
import {
  saveOrUpdateApi,
  pagelistApi,
  updateStatusApi,
  deleteByIdApi
} from "@/api/work/attendance/reimburse";
import {
  addApi,
  listApi,
  getEventDataApi
} from "@/api/work/attendance/receipt";
import { getWorkEnumList } from "@/api/commonApi/commonInternal/commonInternal";
export default {
  data() {
    return {
      statusList: [
        {
          key: 1,
          name: "流程中"
        },
        {
          key: 2,
          name: "完成"
        }
      ],
      typeList: [
        {
          value: 1,
          name: "普票"
        }
      ],
      options: [],
      refAttendanceTravelList: [],
      attendanceList: [],
      title: null,
      dialogVisible: false,
      receiptTypeList: [],
      queryInfo: {
        query: ""
      },
      ids: null,
      editShowAll: true,
      attendType: 1,
      resourcesIdList: []
    };
  },
  watch: {
    isEdit(newValue, oldValue) {
      console.log(newValue, oldValue);
    }
  },
  methods: {
    show(row) {
      this.dialogVisible = true;
      this.ids = row.uniqueId;
      this.getEventDataButton();
      this.getReceiptTypeList();
      this.listApiButton();
      this.listButton();
    },
    handleClose() {
      this.dialogVisible = false;
      this.addFrom = {};
      this.$parent.getAttendanceList();
    },
    listButton() {
      pagelistApi({ refAttendanceId: this.ids }).then(res => {
        this.attendanceList = res.result.records;
      });
    },
    editRowMember(index, row, rowOne) {
      if (rowOne.status != 1) return this.$message.error("完成的不能修改");
      row.isEdit = Boolean(true);
      row[index].isEdit = Boolean(true);
      row.refAttendanceId = this.ids;
      this.editShowAll = true;
    },
    addFlower() {
      var newAttFlow = {
        refAttendanceId: this.ids,
        isEdit: true,
        status: 1
      };
      this.attendanceList.push(newAttFlow);
    },
    deleteRow(index, row, dataList) {
      if (row.id == null) {
        dataList.splice(index, 1);
      } else {
        if (row.status != 1) return this.$message.error("完成的不能删除");
        deleteByIdApi(row.id).then(res => {
          this.listButton();
        });
      }
    },
    getEventDataButton() {
      getEventDataApi({ refAttendanceId: this.ids }).then(res => {
        this.refAttendanceTravelList = res.result;
      });
    },
    getReceiptTypeList() {
      getWorkEnumList({ enumName: "ReceiptTypeEnum" }).then(res => {
        this.receiptTypeList = res.result;
      });
    },
    listApiButton() {
      listApi({ refAttendanceId: this.ids }).then(res => {
        this.resourcesIdList = res.result;
      });
    },
    addRowMember(index, row) {
      row.isEdit = Boolean(false);
      row.refAttendanceId = this.ids;
      updateStatusApi(row).then(res => {
        this.listButton();
      });
      this.editShowAll = false;
    }
  }
};
</script>
<style scoped>
</style>